<template>
  <v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn style="height: 30px; magin: 5px 0" elevation="0" block v-on="on">
        {{ $t("edit", { name: "" }) }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("requested_info") }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text v-if="client_info" class="client_info">
        <v-row align="center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col class="py-0 text-end" cols="12" md="8">
            {{ $t("request") }}
          </v-col>
          <v-col class="py-0" cols="12" md="4"> ¿Obligatorio? </v-col>
        </v-row>
        <br />
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("first_name") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.first_name.view"
              disabled
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.first_name.required"
              disabled
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("last_name") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.first_name.view"
              disabled
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.last_name.required"
              disabled
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("email") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.first_name.view"
              disabled
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.email.required"
              disabled
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("phone") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.phone.view"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.phone.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("customers.birthdate") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.date_of_birth.view"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.date_of_birth.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("customers.height") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.height.view"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.height.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("customers.weight") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.weight.view"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.weight.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("allergies") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.allergies.view"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.allergies.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("native_city") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.nativeCity.view"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.nativeCity.required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="py-0" cols="12" md="5">
            {{ $t("how_did_you_met_us") }}
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              {{ $t("request") }}
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsRequerimiento"
              v-model="client_info.meet.view"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="my_label" v-if="$vuetify.breakpoint.xs">
              ¿Obligatorio?
            </div>
            <v-select
              outlined
              dense
              hide-details
              :items="itemsObligatorio"
              v-model="client_info.meet.required"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="dialog = false"
          style="height: 25px; width: 100px"
          elevation="0"
          outlined
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          color="primary"
          @click="save"
          elevation="0"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
        >
          <v-icon small style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ClientInfo",
  data() {
    return {
      dialog: false,
      itemsRequerimiento: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      itemsObligatorio: [
        { text: this.$t("required"), value: true },
        { text: this.$t("not_required"), value: false },
      ],
      option: true,
      client_info: null,
    };
  },
  mounted() {
    let s = this.$store.getters["auth/getSetting"](
      "requested_client_information"
    );
    if (typeof s === "string") s = JSON.parse(s);
    this.client_info = s;
  },
  methods: {
    ...mapActions("users", ["settingUpdate"]),
    save() {
      this.settingUpdate({
        key: "requested_client_information",
        value: this.client_info,
        json: true,
      }).then((response) => {
        this.$alert(this.$t("save_ok"));
        this.dialog = false;
      });
    },
  },
};
</script>

<style scoped lang="sass">
@media (max-width: 960px)
  .client_info
    .row
      margin-top: 8px
      margin-bottom: 8px
      border: 1px solid var(--v-primary-base)
      border-radius: 10px
      .my_label
        font-size: 10px
</style>